import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { motion, useScroll, useSpring } from 'framer-motion';
import useAppStore from '@/store/_app.js';
//import device from '@/utils/device.js';
import * as Styled from './ScrollIndicator.styled.js';

const propTypes = {
  className  : PropTypes.string,
  container  : PropTypes.object,
  isRelative : PropTypes.bool
};

const defaultProps = {
  className  : '',
  container  : {},
  isRelative : false
};

const ScrollIndicator = (props) => {
  const { className, isRelative, container } = props;

  const {
    loaded
  } = useAppStore(state => ({
    loaded : state.loaded
  }));

  const scrollRef = useRef();

  useEffect(() => {
    if (loaded && typeof document !== 'undefined') scrollRef.current = document.getElementById('scroller3D');
  }, [loaded]);

  const scrollDiv = useMemo(() => container === 'scroller3D' && loaded ? scrollRef : container, [container, loaded]);
  const { scrollYProgress } = useScroll({ container : scrollDiv });
  const scaleY = useSpring(scrollYProgress, {
    stiffness : 100,
    damping   : 30,
    restDelta : 0.001
  });

  return (
    <Styled.Wrapper className={className}>
      <Styled.Bar
        as={motion.div}
        style={{ scaleY }}
        $isRelative={isRelative}
      />
    </Styled.Wrapper>
  );
};

ScrollIndicator.propTypes = propTypes;
ScrollIndicator.defaultProps = defaultProps;

export default ScrollIndicator;
