import styled from 'styled-components';
import { colors, layout } from '@/styles/_theme';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${layout.zIndex.scrollIndicator};
  width: 4px;
  height: 100%;

`;

export const Bar = styled.div`
  position: fixed;
  top: 0;
  left: ${props => props.$isRelative ? 'unset' : 0};
  bottom: 0;
  width: 4px;
  background-color: ${colors.gold};
  transform-origin: top;

`;
