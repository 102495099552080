import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from '@/styles/_theme';
import { setTypography } from '@/styles/_mixins';

export const Wrapper = styled(motion.button)`
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  width: fit-content;
`;
export const Label = styled.p`
  ${setTypography('cta')};
  color: ${ props => props.color === 'black' ? colors.grey1 :  colors.grey5};
  width: fit-content;
  margin: 0;
`;

export const Line = styled.div`
  content: '';
  position: absolute;
  background: ${props => props.$color === '' ? `${colors.gold}80` : `${colors[props.$color]}80`};
  width: 1px;
  height: 48rem;
  left: 50%;
  top: 32rem;
`;
export const LineWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const AnimatedLine = styled(motion.div)`
  content: '';
  position: absolute;
  background: ${props => props.$color === '' ? `${colors.gold}` : `${colors[props.$color]}`};
  width: 100%;
  height: 100%;;
  left: 0;
  top: 0;
`;
