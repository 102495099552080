import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion, useScroll } from 'framer-motion';
import { copy } from '@/locales/en.js';
import * as Styled from './ScrollMore.styled.js';

const propTypes = {
  onClick   : PropTypes.func,
  rest      : PropTypes.object,
  color     : PropTypes.string,
  lineColor : PropTypes.string
};
const defaultProps = {
  onClick   : () => {},
  rest      : {},
  color     : '',
  lineColor : ''
};

const ScrollMore = (props) => {
  const {
    onClick, color, lineColor,  ...rest
  } = props;

  return (
    <Styled.Wrapper onClick={onClick} {...rest}>
      <Styled.Label color={color}>{copy.SCULPTURE_SCROLL_MORE}</Styled.Label>
      <Styled.Line $color={lineColor}>
        <Styled.LineWrapper>
          <Styled.AnimatedLine
            $color={lineColor}
            initial={{ y : '-105%' }}
            animate={{ y : '150%', transition : { repeat : Infinity, duration : 2 } }}
          />
        </Styled.LineWrapper>
      </Styled.Line>
    </Styled.Wrapper>
  );
};

ScrollMore.propTypes = propTypes;
ScrollMore.defaultProps = defaultProps;

export default ScrollMore;
