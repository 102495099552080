import Head from 'next/head';
import { motion, AnimatePresence } from 'framer-motion';
import {
  useEffect, useState, useRef
} from 'react';
import { useRouter } from 'next/router';
import { copy } from '@/locales/en';
import { useAppStore, useCanvasStore, useWindowSizeStore } from '@/store';
import ScrollIndicator from '@/components/ui/ScrollIndicator/ScrollIndicator';
import ScrollMore from '@/components/ui/ScrollMore/ScrollMore';
import Button from '@/components/ui/Button/Button';
import device from '@/utils/device.js';
import { preloadModels } from '@/components/canvas/ThreeScene/ThreeScene.models';
import gtag from '@/utils/gtag';
import { EVENTS } from '@/config/config.analytics';
import * as Styled from './index.styled';

export default function Home() {

  const {
    loaded,
    setIsUserEntered,
    isUserEntered,
    setQrModalLink,
    setQrModalOpened,
    scrolling,
    setScrolling
  } = useAppStore(state => ({
    loaded           : state.loaded,
    setIsUserEntered : state.setIsUserEntered,
    isUserEntered    : state.isUserEntered,
    setQrModalLink   : state.setQrModalLink,
    setQrModalOpened : state.setQrModalOpened,
    scrolling        : state.scrolling,
    setScrolling     : state.setScrolling
  }));

  const {
    sceneName

  }
    = useCanvasStore((state) =>
      ({
        sceneName : state.sceneName
      }));

  const windowSize = useWindowSizeStore();
  const router = useRouter();

  const handleButtonClick = () => {
    // router.push('/amphitheatre');
    setIsUserEntered(true);
  };

  const handleButtonClickAR = () => {
    // console.log('AR experience');
    const baseUrl = process.env.APP_URL || window?.location.origin;

    if (!device.detection.isDesktop) {
      gtag.trackEvent(EVENTS.VIEW_AR);
      if (device.detection.isAndroid) {
        // eslint-disable-next-line max-len
        return window.location = `intent://arvr.google.com/scene-viewer/1.0?file=${baseUrl}/models/Ship_animated.glb&mode=ar_preferred#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
      } else {
        return window.location = `${baseUrl}/models/Ship_animated.usdz`;
      }
    } else {
      setQrModalLink(`${baseUrl}/trireme`);
      setQrModalOpened(true);
    }
  };

  useEffect(() => {
    if (sceneName === 'amphi-zoom-out' ) {
      if (router.route !== '/amphitheatre') router.push('/amphitheatre');
    };
  }, [sceneName]);

  useEffect(() => {
    // preload ampitheatre model when mounted
    preloadModels();
  }, []);
  const [isScrollable, setIsScrollable] = useState(false);
  const scrollRef = useRef();

  useEffect(() => {

    if (isUserEntered) {
      scrollRef.current = document.getElementById('scroller3D');
      setIsScrollable(true);
    }

  }, [isUserEntered]);

  useEffect(() => {
    const handleScroll = (e) => {

      if (e.target.scrollTop > 0 && !scrolling) setScrolling(true);
      if (e.target.scrollTop === 0 && scrolling) setScrolling(false);
    };
    scrollRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollRef.current, scrolling]);

  const subheading = `${copy.INTRO_SUBHEADING[0]}\n${copy.INTRO_SUBHEADING[1]} ${copy.INTRO_SUBHEADING[2]}`;
  const subheadingMobile =
  `${copy.INTRO_SUBHEADING[0]}\n${copy.INTRO_SUBHEADING[1]}\n${copy.INTRO_SUBHEADING[2]}`;
  return (
    <Styled.Wrapper isUserEntered = {isUserEntered}>
      <Head>
        <title>{copy.PAGE_TITLE_BRAND}</title>
        <meta
          property="og:title"
          content={copy.PAGE_TITLE_BRAND}
          key="og:title"
        />
        <meta
          property="og:description"
          key="og:description"
          content={copy.PAGE_DESCRIPTION}
        />
        <meta
          property="og:url"
          content={process.env.APP_URL}
          key="og:url"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        <meta
          name="twitter:title"
          content={copy.PAGE_TITLE_BRAND}
          key="twitter:title"
        />
        <meta
          name="twitter:description"
          content={copy.PAGE_DESCRIPTION}
          key="twitter:description"
        />
      </Head>

      <AnimatePresence>

        {sceneName === 'intro-text' && windowSize.width < 1024 && (
          <Styled.GradientBackground
            transition={{
              duration : 1, ease : 'easeInOut'
            }}
            initial={{ opacity : 0 }}
            animate={{ opacity : 1 }}
            exit={{ opacity : 0 }}
          />
        )}

      </AnimatePresence>

      <AnimatePresence>
        {loaded && !isUserEntered && (
          <Styled.Background
            transition={{
              duration : 1, ease : 'easeInOut'
            }}
            initial={{ opacity : 1 }}
            animate={{ opacity : 1 }}
            exit={{ opacity : 0 }}

          />
        )}

      </AnimatePresence>
      <Styled.Components >
        {(loaded && isUserEntered && isScrollable) && <ScrollIndicator container={scrollRef} />}
        <AnimatePresence exitBeforeEnter>
          {sceneName === 'intro-text' && (
            <Styled.IntroTextWrapper>

              <Styled.IntroText
                transition={{
                  duration : 1, ease : 'easeInOut'
                }}
                initial={{ opacity : 0, y : -50  }}
                animate={{ opacity : 1, y : -0 }}
                exit={{ opacity : 0, y : -50 }}
              >

                {copy.INTRO_DESCRIPTION_SHIP}

              </Styled.IntroText>
              <Styled.ButtonWrapper
                transition={{
                  duration : 1, ease : 'easeInOut', delay : 0.3
                }}
                initial={{ opacity : 0, y : 50  }}
                animate={{ opacity : 1, y : -0 }}
                exit={{ opacity : 0, y : 50 }}
              >
                <Button
                  hasDiamonds={true}
                  animatedBorder={true}
                  onClick={handleButtonClickAR}
                >
                  {copy.INTRO_SHIP_CTA}
                </Button>
              </Styled.ButtonWrapper>

            </Styled.IntroTextWrapper>
          )}
        </AnimatePresence>

        <AnimatePresence >
          {loaded && !isUserEntered && (
            <>
              <Styled.Top
                initial={{
                  opacity : 0, y : 10, x : '-50%', letterSpacing : '0.2em', width : '120%'
                }}
                animate={{
                  opacity : 1, y : 0, x : '-50%', letterSpacing : 0, width : '100%'
                }}
                exit={{ opacity : 0 }}
                transition={{ duration : 1, delay : 0.4 }}
                as={motion.p}
              >
                {copy.INTRO_TOP}
              </Styled.Top>
              <Styled.HeadingIntro subheading={subheading} subheadingMobile={subheadingMobile} />
              <motion.div
                initial={{ opacity : 0.01, y : 30 }}
                animate={{ opacity : 0.99, y : 0 }}
                exit={{ opacity : 0.01, transition : { duration : 1 } }}
                transition={{ duration : 1, delay : 2.2 }}
              >
                <Button
                  hasDiamonds={true}
                  animatedBorder={true}
                  onClick={handleButtonClick}
                >{copy.INTRO_CTA}</Button>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </Styled.Components>
      <AnimatePresence>
        { isUserEntered && !scrolling && (
          <Styled.ScrollMoreWrapper
            transition={{
              duration : 1, ease : 'easeInOut', delay : 1
            }}
            initial={{ opacity : 0 }}
            animate={{ opacity : 1 }}
            exit={{ opacity : 0 }}
          >
            <ScrollMore color={'white'} lineColor={'white'} />
          </Styled.ScrollMoreWrapper>
        )}
      </AnimatePresence>

    </Styled.Wrapper>
  );
}
