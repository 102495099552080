import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fullSize, setTypography, flexCenter } from '@/styles/_mixins';
import { colors, layout } from '@/styles/_theme';
import { mediaDesktop, mediaTablet } from '@/styles/_responsive';
import IntroHeading from '@/components/ui/IntroHeading/IntroHeading';

export const Wrapper = styled.div`
  ${fullSize({ centered : true })}
  z-index: ${layout.zIndex.homePage};
  pointer-events:  ${props => props.isUserEntered ? 'none' : 'auto'} ;
`;
export const Components = styled.div`
  row-gap: 40rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
`;
export const Top = styled.p`
  ${setTypography('detail')};
  position: absolute;
  top: 32rem;
  left: 50%;
  text-align: center;
  color: ${colors.grey2};
  transform: translateX(-50%);
  ${mediaDesktop(`
    top: 54rem;
  `)}
  color: ${colors.grey2};
`;
export const IntroWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${flexCenter()}
  background: ${colors.silver};
  position: relative;
`;
export const HeadingIntro = styled(IntroHeading)`
`;
export const GradientBackground = styled(motion.div)`
position: absolute;
width: 150%;
height: 60%;
bottom: 0%;
background: radial-gradient(${colors.white} , transparent 70%);

${mediaTablet(`
width: 100%;
height: 40%;
bottom:20%;
`)}

${mediaDesktop(`
width: 80%;
height: 100%;
bottom: -10%;
right: -10%;
`)}
`;

export const IntroTextWrapper = styled.div`
  ${flexCenter()}
  position: absolute;
  width: 90%;
  bottom: 15%;



  ${mediaTablet(`
  width: 80%;
  bottom: 20%;
  `)}

  ${mediaDesktop(`

    bottom: unset;
    right: 10%;
    width: 30%;
    top: 23%;
  `)}
`;

export const IntroText = styled(motion.p)`
${setTypography('paragraph')}
  text-align: center;
  margin-bottom: 28rem;
  pointer-events: none;

  ${mediaDesktop(`
  text-align: left;
`)}

`;

export const ButtonWrapper =  styled(motion.div)`
  pointer-events: auto;
  position: fixed;
  left: 10rem;
  top: 18rem;
  ${mediaDesktop(`
    left: 40rem;
    top: unset;
    bottom: 40rem;
  `)}
`;

export const ScrollMoreWrapper = styled(motion.div)`
  position: absolute;
  z-index: 2;
  bottom: 100rem;
  left: 50%;
  transform: translateX(-50%);

`;

export const Background = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${colors.silver};
`;
